<template>
  <div class="stepper-item-com">
    <template>
      <v-card color="transparent" elevation="0" style="">
        <div class="text-center mt-10" style="font-size: .8em;">
          {{ $t('onBoarding.stepTwo.title') }}
          <div>
<!--                                                                <span style="" class="step-subtitle text-right">-->

<!--                                                                  {{ $t('onBoarding.stepTwo.subtitle') }}-->
<!--                                                                </span>-->
          </div>
          <!--          <v-btn @click="nextHandler">sdvsv</v-btn>-->
        </div>
        <v-card-text class="mt-3">

          <v-row class="mx-auto" justify="center"
                 align="center">

            <template
                v-for="(age,n) in dataComponent"
            >
              <SelectableWizardCard
                  @itemSelected="selectItem" :info="info" :item="age"
                  :no-image="true"
                  :key="n"
                  data-key="category"/>
              <div class="cl" style="clear: both;width:100%;" v-if="$vuetify.breakpoint.lgAndUp &&n!=0 && (n+1) % 3 == 0" :key="'key-'+n"></div>
            </template>

          </v-row>
        </v-card-text>

      </v-card>
    </template>
  </div>
</template>

<script>
import SelectableWizardCard from "@/components/wizard/SelectableWizardCard";

export default {
  components: {SelectableWizardCard},
  props: {
    isMobile: Boolean,
    info: Object,
    checkLang: String,
    dataComponent: Array,
    // key:String,
    datakey: String
  },
  methods: {
    nextHandler() {
      this.$emit('nextHandler')
    },
    selectItem(key, value) {
      this.$emit('selectItem', key, value, false, true)
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.stepper-item-com {
  /*width: 100%;*/
  /*min-height: 100%;*/
  /*margin: 0 auto;*/
  /*position: absolute;*/
  /*opacity: 0;*/
  /*display: none;*/
  display: flex !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  transition: background-color .3s linear;
}
</style>