<template>
  <v-app>
    <v-content>
      <v-snackbar
          :id="snackbarId"
          v-model="snackbar.visibility"
          :color="snackbar.color"
          :bottom="snackbar.position === 'bottom'"
          :timeout="snackbar.timeout"
          :top="snackbar.position === 'top'"
      >
        {{ snackbar.message }}
        <v-btn
            color="primary--text"
            @click="snackbar.visibility = false"
            fab
            small
        >
          <v-icon x-small color="red">fas fa-times</v-icon>
        </v-btn>
      </v-snackbar>

      <!--      <v-dialog v-model="loading" max-width="500" persistent>-->
      <!--        <v-card>-->
      <!--          <v-card-text class="pt-5">-->
      <!--            <v-progress-linear-->
      <!--                active-->
      <!--                indeterminate-->
      <!--                query-->
      <!--                rounded-->
      <!--                stream-->
      <!--            ></v-progress-linear>-->
      <!--          </v-card-text>-->
      <!--        </v-card>-->
      <!--      </v-dialog>-->
      <v-container fluid class="pa-0 ma-0  " style="height: 100%;background-color: transparent;">
        <WizardModal :showModal="showPrivacyPolicySwitch" @modalClosed="showPrivacyPolicySwitch=false"></WizardModal>
        <div class="main-container-c " :class="{'blur-content':showPrivacyPolicySwitch}">
          <div class="main-background  ">
            <img class="bg-circles aqua-circle" :src="require('../../assets/new-design/Aqua.svg')" alt="">
            <img class="bg-circles green-circle" :src="require('../../assets/new-design/Green.svg')" alt="">
            <img class="bg-circles purple-circle" :src="require('../../assets/new-design/Purple.svg')" alt="">
          </div>
          <div class=" flex-navigation" style="z-index: 2;padding:5px 0">
            <v-container class="pa-0">
              <v-row no-gutters>
                <v-col class="text-center  d-flex flex-column justify-center ">
                  <router-link :to="{name:'dashboard'}">
                    <v-toolbar-title>
                      <img :src="require('../../assets/simialogopng.png')" style="width: 125px;"
                           class="mt-4  " :class="$vuetify.rtl && 'mr-2'" v-if="getPlatformInfo('id') == 3">
                      <p v-else-if="getPlatformInfo('id') == 4" class="mt-3">
                        {{ getPlatformTitle() }}
                      </p>
                    </v-toolbar-title>
                  </router-link>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-center  my-2" :class="$vuetify.rtl && 'mr-12'">
                  <WizardLoginRegisterButtonMobile
                      :activeBtn="loginRegisterBtn"
                      @changeMethod="setLoginRegisterWindow"
                      v-if="$vuetify.breakpoint.smAndDown"
                  ></WizardLoginRegisterButtonMobile>
                  <WizardLoginRegisterBtn :activeBtn="loginRegisterBtn"
                                          v-else
                                          @changeMethod="setLoginRegisterWindow"></WizardLoginRegisterBtn>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <!--          <div class="priceBoxArea pa-0 flex-grow-1 red">-->
          <!--            sdvsdvsd-->
          <!--          </div>-->
          <v-container class="stepper-shell  pa-0" ref="stepper-shell" style="display: block;flex-grow: 1"
                       :style="$vuetify.breakpoint.lgAndUp?'width:60%;z-index:2;position:relative;':'z-index:2;position:relative;'">
            <v-fade-transition>
              <WizardStepIndicator v-if="activeStep.id > 1" :step="activeStep.id"
                                   class="my-2 mb-7"></WizardStepIndicator>
            </v-fade-transition>

            <div class="stepper " :class="activeStep.step != 1 ? 'stepper-item-colored' : '' " ref="stepper">
              <!--              {{renderList.length}}-->
              <template v-for="(step , i) in renderList">
                <div :key="i" :ref="`step${step.step}`" class="stepper-item" :class="'step-'+step.key">
                  <component
                      :is="step.component"
                      @nextHandler="nextHandler($event)"
                      :data-component="step.dataComponent"
                      @selectItem="selectItem"
                      @skip="skip"
                      :info="info"
                      :datakey="step.key"
                      @whoSelectHandler="setWhoSelectDoctor"
                      @selectingTime="selectingTime($event)"
                      :whoselectDoctor="whoSelectDoctor"
                      :ismobile="isMobile"
                      :loginwindow="loginWindow"
                      @afterLogin="afterLogin"
                  ></component>
                </div>
              </template>
            </div>
            <WizardNavButtons_refactor
                v-if="activeStep.step !== 1 && activeStep.step != renderList.length"
                @nextHandler="nextHandler"
                @previousHandler="previousHandler"
                :can-next="canNext"
                :can-prev="canPrev"
            ></WizardNavButtons_refactor>
          </v-container>

          <v-container
              :style="`transition:all .2s linear;width:${$vuetify.breakpoint.lgAndUp && activeStep.step == 1 ?'62%':shellWidth};z-index:2`"
              class="pt-0  ">
            <div :style="`position:relative;text-align:right;`" class="ma-0">
              <v-row no-gutters>
                <v-col class="ma-0 pa-0" :class="$route.params.lang == 'en' ? 'text-left' : '' ">
                  <span :style="`font-size: .5em;width:100%;position:relative;`"
                        class="rules-and-conditions" @click="showPrivacyPolicy"
                        :class="{'active':activeStep.step == renderList.length && loginRegisterBtn!='login'}"
                  >
                          {{ $t('onBoarding.terms') }}{{ getPlatformTitle() }}
                  </span>

                  <v-fade-transition>
                    <div class="arrow-left" v-if="activeStep.step == renderList.length && loginRegisterBtn!='login'"
                         :style="checkLang == 'fa' ? 'right: -10px;top: 17px;' : '  left: -10px;transform: rotate(180deg);top: 25px;'"></div>
                    <!--                    <div class="arrow-left" ></div>-->
                  </v-fade-transition>
                </v-col>
                <v-col class="text-left" v-if="!$vuetify.breakpoint.xs"
                       :class="$route.params.lang == 'en' ? 'text-right' : '' ">
                  <a :href="item.link" v-for="(item,n) in socialLinks" :key="n" target="_blank">
                    <v-icon class="social-icons mx-2" size="18">{{ item.icon }}</v-icon>
                  </a>
                </v-col>
              </v-row>
              <!--              <TestimonialsOnboarding class="mt-10"/>-->

              <!--              -->
              <!--              <div class="mr-0 " >-->
              <!--              -->
              <!--                <v-spacer></v-spacer>-->

              <!--                -->
              <!--              </div>-->
            </div>
          </v-container>
        </div>


      </v-container>
    </v-content>
  </v-app>
</template>

<script>
/*eslint-disable*/
import gsap from 'gsap';
// import LoginComponent from '@/components/LoginComponentWizard.vue'
// import SignupComponent from '@/components/SignUpComponentWizard.vue'
// import LS from "../LocalStorateAPI";
import {mapGetters} from 'vuex';
// import {EventBus} from "../event-bus";
// import SelectWizardDoctor from "@/components/wizard/SelectWizardDoctor";
// import SelectTimeWizard from "@/components/wizard/selectTimeWizard";
import TestimonialsOnboarding from "@/components/OnBoarding/TestimonialsOnboarding";
import SelectWizardWhoSelectDoctor from "@/components/wizard/SelectWizardWhoSelectDoctor";
import SelectCategoryOnboarding from "@/components/OnBoarding/SelectCategoryOnboarding";
import SelectGenderOnboarding from "@/components/OnBoarding/selectGenderOnboarding";
import SelectAgeOnBoarding from "@/components/OnBoarding/selectAgeOnBoarding";
import SelectReletionsOnBoarding from "@/components/OnBoarding/selectReletionsOnBoarding";
// import SelectWhoSelectDoctor from "@/components/OnBoarding/SelectWhoSelectDoctor";
// import SelectTimeOnBoariding from "@/components/OnBoarding/selectTimeOnBoariding";
// import SelectDoctorOnBoarding from "@/components/OnBoarding/selectDoctorOnBoarding";
import RegisterStepOnboarding from "@/components/OnBoarding/registerStepOnboarding";
import WizardNavButtons_refactor from "@/components/OnBoarding/WizardNavButtons_refactor";
import WizardLoginRegisterBtn from "@/components/wizard/WizardLoginRegisterBtn";
import WizardLoginRegisterButtonMobile from "@/components/wizard/WizardLoginRegisterButtonMobile";
import WizardStepIndicator from "@/components/wizard/WizardStepIndicator_refactor";
import WizardModal from '@/components/wizard/WizardModal';
import WizardStep1_new from "@/components/OnBoarding/WizardStep1_new";
import SelectCountryOnboarding from "@/components/OnBoarding/SelectCountryOnboarding";
import SelectPsyHistory from "@/components/OnBoarding/SelectPsyHistory";

// import PriceBoxAfterLogin from "@/components/OnBoarding/priceBoxAfterLogin";

export default {
  components: {
    SelectPsyHistory,
    SelectCountryOnboarding,
    // PriceBoxAfterLogin,
    WizardNavButtons_refactor,
    RegisterStepOnboarding,
    // SelectDoctorOnBoarding,
    // SelectTimeOnBoariding,
    // SelectWhoSelectDoctor,
    SelectReletionsOnBoarding,
    SelectAgeOnBoarding,
    SelectGenderOnboarding,
    SelectCategoryOnboarding,
    SelectWizardWhoSelectDoctor,
    TestimonialsOnboarding,
    // SelectTimeWizard,
    // SelectWizardDoctor,
    // LoginComponent,
    // SignupComponent,
    // SelectableWizardCard,
    // WizardNavButtons,
    // WizardIndicator,
    // WizardStep1,
    WizardLoginRegisterBtn,
    WizardLoginRegisterButtonMobile,
    WizardStepIndicator,
    WizardModal,
    WizardStep1_new,

  },
  computed: {
    ...mapGetters({
      'loading': 'loading',
      'snackbarId': 'getSnackbarId',
      'userIsLoggedIn': 'userIsLoggedIn',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    shellWidth() {
      return this.$vuetify.breakpoint.smAndDown ? '100%' : '70%';
    },
    checkLang() {
      return this.$route.params.lang == "fa" || this.$route.params.lang == undefined ? 'fa' : 'en';
    },
    stepList() {
      return [
        {
          id: 1,
          component: 'WizardStep1_new',
          dataComponent: [],
          animation: 'startAnimation',
          key: 'start',
        },
        {
          id: 2,
          component: 'SelectGenderOnboarding',
          dataComponent: [
            {
              title: this.$t('onBoarding.stepThree.gender[0]'),
              image: require('../../assets/new-design/genders/male.svg'),
              val: 'مرد'
            },
            {
              title: this.$t('onBoarding.stepThree.gender[1]'),
              image: require('../../assets/new-design/genders/female.svg'),
              val: 'زن',
            },
            {
              title: this.$t('onBoarding.stepThree.gender[2]'),
              image: require('../../assets/new-design/genders/other.svg'),
              val: 'موارد دیگر'
            },
          ],
          animation: 'slide',
          key: 'gender',
        },
        {
          id: 3,
          component: 'SelectAgeOnBoarding',
          dataComponent: [
            {
              title: this.$t('onBoarding.stepFour.agePeriods[0]'),
              val: 'کمتر از 18',
            },
            {
              title: '19 - 29',
              val: '19 - 29',
            },
            {
              title: '30 - 40',
              val: '30 - 40',
            },
            {
              title: '41 - 50',
              val: '41 - 50',
            },
            {
              title: '51 - 60',
              val: '51 - 60',
            },
            {
              title: this.$t('onBoarding.stepFour.agePeriods[1]'),
              val: 'بیشتر از 60',
            },
          ],
          animation: 'slide',
          key: 'age',
        },

        //country
        {
          id: 4,
          component: 'SelectCountryOnboarding',
          dataComponent: [],
          animation: 'slide',
          key: 'country',
        },


        {
          id: 5,
          component: 'SelectCategoryOnboarding',
          dataComponent: [
            {
              title: this.$t('onBoarding.stepTwo.cycles[0]'),
              // image: require('../../assets/new-design/cycles/individual.svg'),
              val: 'فردی',
            },
            {
              title: this.$t('onBoarding.stepTwo.cycles[1]'),
              // image: require('../../assets/new-design/cycles/relation.svg'),
              val: ' زوج درمانی',
            },
            {
              title: this.$t('onBoarding.stepTwo.cycles[2]'),
              // image: require('../../assets/new-design/cycles/teen.svg'),
              val: 'کودک و نوجوان',
            },
            {
              title: this.$t('onBoarding.stepTwo.cycles[3]'),
              // image: require('../../assets/new-design/cycles/teen.svg'),
              val: 'خانواده',
            }
            , {
              title: this.$t('onBoarding.stepTwo.cycles[4]'),
              // image: require('../../assets/new-design/cycles/teen.svg'),
              val: 'روانپزشکی',
            },
            {
              title: this.$t('onBoarding.stepTwo.cycles[5]'),
              // image: require('../../assets/new-design/cycles/teen.svg'),
              val: ' سکس تراپی',
            },
          ],
          animation: 'slide',
          key: 'category',
        },


        {
          id: 6,
          component: 'SelectPsyHistory',
          dataComponent: [
            {
              title: this.$t('yes'),
              val: 'بله',
            },
            {
              title: this.$t('no'),
              val: 'خیر',
            },


          ],
          animation: 'slide',
          key: 'psy_history',
        },
        //sabghe psy

        // {
        //   id: 5,
        //   component: 'SelectReletionsOnBoarding',
        //   dataComponent: [
        //     {
        //       title: this.$t('onBoarding.stepFive.relationships[0]'),
        //       val: 'مجرد',
        //     },
        //     {
        //       title: this.$t('onBoarding.stepFive.relationships[1]'),
        //       image: require('../../assets/new-design/relationship/married.svg'),
        //       val: 'متاهل'
        //     },
        //     {
        //       title: this.$t('onBoarding.stepFive.relationships[2]'),
        //       image: require('../../assets/new-design/relationship/relationship.svg'),
        //       val: 'شریک عاطفی'
        //     },
        //     {
        //       title: this.$t('onBoarding.stepFive.relationships[3]'),
        //       image: require('../../assets/new-design/relationship/other.svg'),
        //       val: 'موارد دیگر'
        //     },
        //   ],
        //   animation: 'slide',
        //   key: 'relationship',
        // },
        // {
        //   id: 6,
        //   component: 'SelectWhoSelectDoctor',
        //   dataComponent: [],
        //   animation: 'slide',
        //   key: 'whoSelectDoctor',
        // },
        // {
        //   id: 7,
        //   component: 'SelectDoctorOnBoarding',
        //   dataComponent: this.doctors,
        //   animation: 'slide',
        //   key: 'doctor',
        // },
        // {
        //   id: 8,
        //   component: 'SelectTimeOnBoariding',
        //   dataComponent: this.days,
        //   animation: 'scrollUp',
        //   key: 'times',
        // },
        {
          id: 7,
          component: 'RegisterStepOnboarding',
          dataComponent: [],
          animation: 'slide',
          key: 'register',
        },
      ]
    },
    renderList() {
      let step = 1;
      return this.stepList.filter(item => {
        // if (item.component === 'SelectDoctorOnBoarding') {
        //   if (this.whoSelectDoctor === 'me') {
        //     item.step = step
        //     step++;
        //     return item;
        //   }
        // } else {
        //   item.step = step
        //   step++;
        //   return item;
        // }
        if (item.component == 'SelectWhoSelectDoctor' || item.component == 'SelectDoctorOnBoarding') {
          return;
        } else {
          item.step = step
          step++;
          return item;
        }
        // if (item.key == 'start' || item.key == "doctor") {
        //
        //   item.step = step
        //   step++;
        //   return item;
        // }
      })
    },
    canNext() {
      if (this.activeStep.key) {
        if (this.info[this.activeStep.key] != null) {
          return true;
        } else {
          return false
        }
      } else {
        return true
      }
    },
    canPrev() {
      return true;
    },
  },
  data() {
    return {
      times: [
        {
          title: "8-12",
          pic: 'morning.png'
        },
        {
          title: '12-16',
          pic: 'morning.png'
        },
        {
          title: '16-20',
          pic: 'morning.png'
        },
        {
          title: '20-24',
          pic: 'morning.png'
        }

      ],
      days: {
        Sa: [],
        Su: [],
        Mo: [],
        Tu: [],
        We: [],
        Th: [],
        Fr: [],
      },
      snackbar: {
        visibility: false,
        color: 'info',
        position: 'bottom',
        timeout: 5000,
        mode: 'vertical',
        message: 'this is snackbar message',
      },
      showMethods: false,
      showPrivacyPolicySwitch: false,
      socialLinks: [
        {link: 'https://www.youtube.com/simiaroom', icon: 'fab fa-youtube'},
        {link: 'https://twitter.com/simiaroom', icon: 'fab fa-twitter'},
        {link: 'https://www.facebook.com/simiatalk', icon: 'fab fa-facebook'},
        {link: 'https://www.linkedin.com/company/simiaroom/', icon: 'fab fa-linkedin'},
        {link: 'https://www.instagram.com/simiaroomcom/', icon: 'fab fa-instagram'},
        {link: 'https://wa.me/00989302672054', icon: 'fab fa-whatsapp'},
      ],
      loginRegisterBtn: 'register',
      loginWindow: 2,
      relationships: [
        {
          title: this.$t('$vuetify.onBoarding.stepFive.relationships[0]'),
          image: require('../../assets/new-design/relationship/single.svg'),
          val: 'مجرد',
        },
        {
          title: this.$t('$vuetify.onBoarding.stepFive.relationships[1]'),
          image: require('../../assets/new-design/relationship/married.svg'),
          val: 'متاهل'
        },
        {
          title: this.$t('$vuetify.onBoarding.stepFive.relationships[2]'),
          image: require('../../assets/new-design/relationship/relationship.svg'),
          val: 'شریک عاطفی'
        },
        {
          title: this.$t('$vuetify.onBoarding.stepFive.relationships[3]'),
          image: require('../../assets/new-design/relationship/other.svg'),
          val: 'موارد دیگر'
        },
      ],
      agePeriods: [
        {
          title: this.$t('$vuetify.onBoarding.stepFour.agePeriods[0]'),
          val: 'کمتر از 18',
        },
        {
          title: '19 - 25',
          val: '19 - 25',
        },
        {
          title: '26 - 30',
          val: '26 - 30',
        },
        {
          title: '31 - 35',
          val: '31 - 35',
        },
        {
          title: '36 - 45',
          val: '36 - 45',
        },
        {
          title: this.$t('$vuetify.onBoarding.stepFour.agePeriods[1]'),
          val: 'بیشتر از 45',
        },
      ],
      genders: [
        {
          title: this.$t('$vuetify.onBoarding.stepThree.gender[0]'),
          image: require('../../assets/new-design/genders/male.svg'),
          val: 'مرد'
        },
        {
          title: this.$t('$vuetify.onBoarding.stepThree.gender[1]'),
          image: require('../../assets/new-design/genders/female.svg'),
          val: 'زن',
        },
        {
          title: this.$t('$vuetify.onBoarding.stepThree.gender[2]'),
          image: require('../../assets/new-design/genders/other.svg'),
          val: 'موارد دیگر'
        },
      ],
      cycles: [
        {
          title: this.$t('$vuetify.onBoarding.stepTwo.cycles[0]'),
          image: require('../../assets/new-design/cycles/individual.svg'),
          val: 'فردی',
        },
        {
          title: this.$t('$vuetify.onBoarding.  stepTwo.cycles[1]'),
          image: require('../../assets/new-design/cycles/relation.svg'),
          val: 'رابطه و زوج درمانی',
        },
        {
          title: this.$t('$vuetify.onBoarding.stepTwo.cycles[2]'),
          image: require('../../assets/new-design/cycles/teen.svg'),
          val: 'کودک و نوجوان',
        },
      ],
      currentStep: 1,
      step1: null,
      step2: null,
      timeline: null,
      info: {
        category: null,
        gender: null,
        age: null,
        relationship: null,
        doctor: null,
        times: null,
        country: null,
        psy_history: null,
      },
      stepVal: {
        step2: 'gender',
        step3: 'age',
        step4: 'country',
        step5: 'category',
        step6: 'psy_history',
      },
      whoSelectDoctor: null,
      needToGetCycle: true,

      activeStep: null,
      isNextLock: false,
      isPrevLock: false
    }
  },
  created() {
    // console.log(this.renderList, '12345678')
    this.activeStep = this.renderList.find(item => item.step === 1)
    this.$onEvent('setLoading', this.setLoading);
    if (this.$route.params.lang == undefined) {
      // EventBus.$on('notify', this.showSnackbar);
    }
  },
  beforeDestroy() {
    // EventBus.$off('setLoading', this.setLoading);
    // EventBus.$off('notify', this.showSnackbar);
  },
  mounted() {

    // this.next();
    if (this.getCycleFromRoute(this.$route.query.cycle) != false) {
      this.needToGetCycle = false;
    }
    if (this.$route.query.start === "2") {
      this.nextHandler();
    }
    // this.$refs.step1 = this.$refs.step1
    // this.$refs.step2 = this.$refs.step2
    // gsap = new gsap.timeline();
    gsap.fromTo(this.$refs.step1, .5, {x: 0, opacity: 0}, {
      x: 0,
      opacity: 1,
      display: 'flex'
    });

    // this.canNext()
  },
  methods: {
    redirectToPreorderWithPackage() {
      if (this.platFormInfoLS?.id != 4) {
        if (this.$route.query.package_id != null) {
          this.$router.push(`/fa/preorder/sign/${this.$route.query.package_id}`);
        } else {
          this.$router.push('/fa/preorder/sign/68');
        }
      } else {
        this.$router.push('/fa/panel/packages')
      }
    },
    skip() {
      // console.log(this.renderList.find(item => item.id===9));
      if (!this.userIsLoggedIn) {
        this.nextScroll(9)
      } else {
        this.redirectToPreorderWithPackage()
      }
      // alert('skipped')
      // this.$router.push({name:'xpreorder',params:{orderId:this.$route.query.package_id || 68}})
    },
    getDoctorList() {
      this.$store.dispatch('getDoctorListOnBoarding').then((resp) => {
        // console.log(resp)
      })
    },
    setWhoSelectDoctor(val) {
      this.whoSelectDoctor = val
      this.selectItem('whoSelectDoctor', val, '', true)
      // console.log(this.info)
    },
    selectingTime(data) {
      let target = this.days[data.key];
      let indexTarget = target.findIndex(item => item == data.value)
      if (indexTarget == -1) {
        this.days[data.key].push(data.value)
      } else {
        this.days[data.key].splice(indexTarget, 1)
      }

      this.prepareTimesForSave()

    },
    getDoctorsList() {
      this.$store.dispatch('getAllDoctors').then((resp) => {
        // console.log(resp)
      })
    },
    showSnackbar(color = 'success', timeout = 5000, message = 'عملیات با موفقیت انجام شد.', snackbarId = null) {
      this.snackbar.visibility = true;
      this.snackbar.color = color;
      this.snackbar.position = 'bottom';
      this.snackbar.timeout = timeout;
      this.snackbar.mode = 'vertical';
      this.snackbar.message = message;

      if (snackbarId != null) {
        this.$store.commit('SET_SNACKBAR_ID', snackbarId)
      }

    },
    setLoading(loading) {
      this.$store.commit('setLoading', loading);
    },
    animateLoginMethods() {
      if (this.showMethods) {
        gsap.fromTo(this.$refs.loginMethods, {
          height: '40px',
          opacity: 1,
          display: 'flex',
          duration: 0
        }, {
          height: '0px',
          opacity: 0,
          duration: .5,
          display: 'none'
        });
        this.showMethods = false;
      } else {
        gsap.fromTo(this.$refs.loginMethods, {
          height: '0px',
          opacity: 0,
          display: 'none',
          duration: 0
        }, {
          height: '40px',
          opacity: 1,
          duration: .5,
          display: 'flex'
        });
        this.showMethods = true;
      }

    },
    loginBy(target) {
      switch (target) {
        case 'google':
          window.location.href = 'https://api.simiaroom.com/api/v1/auth/social/google';
      }
    },
    prepareTimesForSave() {
      let dates = {};
      let keys = Object.keys(this.days)
      keys.forEach(item => {
        if (this.days[item].length >= 1) {
          let x = {}
          x[item] = this.days[item];
          Object.assign(dates, x)
        }
      })

      this.selectItem('times', Object.keys(dates) != 0 ? dates : null, '', false)
      this.$refs.testRef.checkNextBtnForTimes();
    },
    afterLogin() {
      this.$emitEvent('setLoading', true)
      this.$store.dispatch('postSurveyAnswers', this.info).finally(() => {
        this.$emitEvent('setLoading', true)
        this.redirectToPreorderWithPackage()
      });
    },
    showPrivacyPolicy() {
      this.showPrivacyPolicySwitch = true
    },
    tempDisableOverflow() {
      this.$refs.stepper.style.overflow = 'hidden';
      setTimeout(() => {
        this.$refs.stepper.style.overflow = 'auto';
      }, 700)
    },
    setLoginRegisterWindow(win) {
      this.loginRegisterBtn = win;
      if (win == 'register') {
        this.loginWindow = 2;

      } else {
        this.loginWindow = 1;
      }
    },
    updateWindow(variable) {
      this.loginWindow = variable
      this.loginRegisterBtn = variable == 1 ? 'login' : 'register';
    },
    selectItem(destinationKey, target, multiselect = false, nextSlide = true) {
      if (!multiselect) {
        this.info[destinationKey] = target;
      }
      if (nextSlide) {
        setTimeout(this.nextHandler, 300)
      }

    },
    getCycleFromRoute(val) {
      switch (val) {
        case "childandteen":
          return 'کودک و نوجوان'
        case "individual":
          return 'فردی'
        case 'relationship':
          return 'رابطه و زوج درمانی'
        case "child":
          return "'کودک و نوجوان'"
        case "psychiatrist":
          return "روانپزشکی"
        default:
          return false
      }
    },
    nextHandler() {
      // console.log(this.info)
      let stepInfo = this.activeStep
      if (!this.isNextLock) {
        this.isNextLock = true
        switch (stepInfo.animation) {
          case 'startAnimation':
            return this.startAnimation();
          case 'slide':
            return this.nextSlide()
          case 'scrollUp':
            return this.nextScroll()
        }
      }
    },

    previousHandler() {
      let stepInfo = this.activeStep
      if (!this.isPrevLock) {
        this.isPrevLock = true
        switch (stepInfo.animation) {
          case 'startAnimation':
            return this.startAnimation();
          case 'slide':
          case 'scrollUp':
            return this.prevSlide()
        }
      }
    },
    startAnimation() {
      gsap.to(this.$refs['stepper-shell'], .3, {width: this.shellWidth});
      this.nextSlide()
    },
    nextSlide(nextId = null) {
      // alert(this.activeStep.id)
      let x = 0;
      if (this.activeStep.id != 1) {
        x = 50;
      }
      if (!this.$vuetify.rtl) x = -x
      this.$nextTick().then(() => {
        gsap.to(this.$refs[`step${this.activeStep.step}`], .5, {x, opacity: 0, display: 'none'})
        if (nextId != null) {
          this.activeStep = this.renderList.find(item => item.id === nextId)
        } else {
          this.activeStep = this.renderList.find(item => item.step === this.activeStep.step + 1)
        }

        gsap.fromTo(this.$refs[`step${this.activeStep.step}`], {x: -x, opacity: 0, display: 'none'}, {
          x: 0,
          opacity: 1,
          display: 'flex',
        }).then(() => {
          this.isNextLock = false
        })
      })
    },
    nextScroll(nextId = null) {
      this.tempDisableOverflow()
      if (!this.userIsLoggedIn) {
        gsap.fromTo(this.$refs[`step${this.activeStep.step}`], {
          // position: 'absolute'
        }, {
          y: '-100%',
          opacity: 1,
          display: 'none',
          // position: 'absolute',
        });
        if (nextId != null) {
          this.activeStep = this.renderList.find(item => item.id === nextId)
        } else {
          this.activeStep = this.renderList.find(item => item.step === this.activeStep.step + 1)
        }
        gsap.fromTo(this.$refs[`step${this.activeStep.step}`], {
          y: '100%',
          opacity: 1,
          display: 'flex',
          // position: 'absolute',
          duration: 0
        }, {
          y: 0,
          opacity: 1,
          display: 'flex',
          duration: .6,
          // position: 'absolute',
        });
      } else {
        this.afterLogin()
      }
    },
    prevSlide() {
      let x = 50
      if (!this.$vuetify.rtl) x = -x;

      gsap.fromTo(this.$refs[`step${this.activeStep.step}`], {x: 0, opacity: 1, display: 'flex'}, {
        x: -x,
        opacity: 0,
        display: 'none'
      });
      this.activeStep = this.renderList.find(item => item.step === this.activeStep.step - 1)
      gsap.to(this.$refs[`step${this.activeStep.step}`], .5, {
        x: 0,
        opacity: 1,
        display: 'flex'
      }, '<').then(() => {
        this.isPrevLock = false
      });
    },

  },
  watch: {
    showPrivacyPolicySwitch() {

    },
    currentStep(newVal, oldVal) {
      if (oldVal < newVal) {
        if (newVal === 2 && !this.isMobile) {
          let items = this.$refs['select-card'].map(item => item.$el);
          gsap.from(items, .3, {y: -8, opacity: 0, stagger: -.05});
        }
      }
      if (newVal === 6) {
        LS.set('surveyData', this.info)
        gsap.to(this.$refs.loginMethods, .1, {
          display: 'none',
          height: 0,
        });
      }
    }
  },
}
</script>

<style>

body {
  overflow: hidden;
}

.disableStepper {
  display: none;
  transform: translateX(-100%);
}

.dayLabel {
  color: #1c53c1;
  font-size: 12px;
}

.greyRow {
  background: #FBFCFF;
  height: 50px;
}

.headerMobileTimes {
  background: url("../../assets/newOnboading/backHeaderMobile.png");
  background-size: cover;
  width: 150%;
  margin-right: -50px;
  padding-right: 50%;
  padding-left: 50%;
}

.timesArea {
  /*background: #d1fdff;*/
  background: url("../../assets/newOnboading/backCom.svg");
  background-size: cover;
  border: solid 1px #d1dcff;
  border-radius: 10px;
  width: 77px;
  /*padding: 20px;*/
}

.times {
  font-size: 12px;
  color: #1c53c1;
  margin-top: 20px;
  width: 100%;
}

.timesLabel {
  font-size: 12px;
  /*margin: -10px !important;*/
  /*margin: -25px !important;*/
}

.titleSelectTime {
  font-size: 14px;
}

.subTitleSelectTime {
  margin-top: -10px;
  font-size: 12px;
}


.doctorSelectArea {
  width: 300px;
}

.stepper-shell {
  /*width: 60%;*/
  height: 50%;
  max-height: 700px;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  border-radius: 20px;
}

@media screen and (max-width: 967px ) {
  .stepper-shell {
    max-width: 95% !important;
  }
}

@media screen and (max-width: 500px) {
  .stepper {
    height: 80% !important;
  }

  body {
    overflow: auto !important;
  }

  .login-register-forms {
    width: 100%;
  }
}

.stepper {
  height: 85%;
  margin: 10px 10px 0 10px;
  position: relative;
  border-radius: 20px;
  overflow-x: hidden !important;
}

.stepper-item-active {
  opacity: 1;
}

.stepper-item {
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  position: absolute;
  opacity: 0;
  display: none;
  /*display: flex!important;*/
  justify-content: center;
  flex-direction: row;
  align-items: center;
  transition: background-color .3s linear;
}

.stepper-item-colored {
  background-color: #f5faff;
}

.main-container-c {
  font-size: 24px;
  display: flex;
  position: relative;
  justify-content: right;
  align-items: center;
  height: 100% !important;
  flex-direction: column;
  z-index: 3 !important;
  transition: all .3s linear;
}


.flex-navigation {
  width: 100%;
  z-index: 10;
  align-self: flex-start;

}

.bg-circles {
  position: absolute;
  z-index: 2;
  filter: blur(46px);
  -webkit-filter: blur(46px);
}

.bg-circles.aqua-circle {
  top: -10%;
  left: -5%;
  width: 30%;
}

.bg-circles.green-circle {
  bottom: 12%;
  right: -10%;
  width: 20%;
}

.bg-circles.purple-circle {
  bottom: -15%;
  left: -5%;
  width: 20%;
}

.main-background {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #f9ffff !important;
  z-index: 1;
}


.login-methods-item:hover {
  background-color: white !important;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.07);
}

.login-methods-item {
  width: 45%;
  margin: 0 auto;
  border-radius: 10px !important;
  border: solid 2px #dbdbdb !important;
  transition: all .3s linear !important;
  cursor: pointer;
  box-shadow: none;
}

.login-decision-circle {
  width: 30px;
  height: 30px;
  background-color: #f5faff;
  border: 1px solid #6d5aac;
  border-radius: 50px;
  position: absolute;
  top: 10%;
  font-size: .5em;
  line-height: 30px;
  color: #574596
}

.login-methods-mobile {
  width: 100%;
  height: 50px;
}

.step-subtitle:before {
}

.step-subtitle {
  color: #6d6d6d;
  font-size: .6em;
  position: relative;
}

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Medium.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Medium.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Medium.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Medium.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Medium.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Medium.svg#Estedad-FD-Medium') format('svg');*/
/*  font-weight: 500;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-SemiBold.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-SemiBold.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-SemiBold.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-SemiBold.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-SemiBold.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-SemiBold.svg#Estedad-FD-SemiBold') format('svg');*/
/*  font-weight: 600;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Bold.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Bold.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Bold.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Bold.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Bold.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Bold.svg#Estedad-FD-Bold') format('svg');*/
/*  font-weight: bold;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Thin.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Thin.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Thin.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Thin.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Thin.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Thin.svg#Estedad-FD-Thin') format('svg');*/
/*  font-weight: 100;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-ExtraBold.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-ExtraBold.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraBold.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraBold.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraBold.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraBold.svg#Estedad-FD-ExtraBold') format('svg');*/
/*  font-weight: bold;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Black.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Black.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Black.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Black.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Black.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Black.svg#Estedad-FD-Black') format('svg');*/
/*  font-weight: 900;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-ExtraLight.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-ExtraLight.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraLight.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraLight.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraLight.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraLight.svg#Estedad-FD-ExtraLight') format('svg');*/
/*  font-weight: 200;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Regular.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Regular.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Regular.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Regular.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Regular.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Regular.svg#Estedad-FD-Regular') format('svg');*/
/*  font-weight: normal;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Light.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Light.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Light.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Light.woff') format('woff'),*/
/*    !*url('../assets/fonts/estedad/Estedad-FD-Light.ttf') format('truetype'),*! url('../assets/fonts/estedad/Estedad-FD-Light.svg#Estedad-FD-Light') format('svg');*/
/*  font-weight: 300;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: "Roboto";*/
/*  src: url("../assets/fonts/Roboto-Medium.ttf");*/
/*}*/

.estedad-font {
  font-family: 'Estedad-FD' !important;
}

.arrow-left {
  width: 0;
  height: 0;
  position: absolute;

  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 6px solid #4576d8;
}

.step-subtitle-bullet {
  background-color: #707070;
  width: 5px;
  height: 5px;
  border-radius: 10px;
  position: absolute;
  top: 8px;
  /*right: -8px;*/
}

.social-icons {
  transition: all .3s linear;
}

.social-icons:hover {
  color: black;
}

.rules-and-conditions {
  transition: all .3s linear;
  color: #78a5ff;
  cursor: pointer;
  margin-right: -5px;
}

.rules-and-conditions.active {
  color: #4576d8 !important;
  margin-right: 0px;
}

.rules-and-conditions:hover {
  color: #4576d8 !important;
}

.blur-content {
  /*filter: blur(5px);*/
  /*-webkit-filter: blur(5px);*/
}

.mobile-methods-of-login {
  border: solid 1px #6d5aac;
  border-radius: 10px !important;
  width: 90%;
  margin: 0 auto;
}

.login-methods {
  display: flex;
  line-height: 30px;
  text-align: center;
}
</style>
