<template>
  <div class="mx-auto mt-1" style="width: 100px;min-width: 150px;">
    <router-link :to="{name:'login'}" style="text-decoration: none">
      <div v-ripple="false" class="buttons flex-grow-1" @click="changeMethod('login')"
           :class="{'white--text':method=='login'}">
        <div class="d-flex justify-center">
          <template v-if="$vuetify.rtl">
            {{ $t('onBoarding.wizardLogin[1]') }}
          </template>
          <img ref="register-icon" :src="require('../../assets/new-design/enter.svg')"
               width="15px;" alt="" class="mr-2">
          <template v-if="!$vuetify.rtl">
            {{ $t('onBoarding.wizardLogin[1]') }}
          </template>
        </div>
      </div>
    </router-link>
  </div>

</template>

<script>
import gsap from 'gsap';

export default {
  props: {
    activeBtn: {
      default: 'register'
    }
  },
  mounted() {
    this.changeMethod(this.method)
  },
  computed: {
    position() {
      return this.method == 'login' ? 0 : 50;
    }
  },
  data() {
    return {
      method: 'register'
    }
  },
  methods: {
    changeMethod(method, emit = true) {
      this.method = method;
      let target = method == 'login' ? 'register' : 'login';
      let tl = new gsap.timeline();

      tl.fromTo(this.$refs[target + '-icon'], {
        opacity: 1,
        display: 'block',
        duration: 0
      }, {
        opacity: 0,
        display: 'none',
        duration: .2
      })
      tl.fromTo(this.$refs[method + '-icon'], {
        opacity: 0,
        display: 'none',
        duration: 0
      }, {
        opacity: 1,
        display: 'block',
        duration: .2
      })
      if (emit)
        this.$emit('changeMethod', this.method)
    }
  },
  watch: {
    activeBtn(val) {
      this.changeMethod(val, false)
    }
  }
}
</script>

<style scoped>
.buttons-shell {

  border-radius: 10px;
  border: solid 1px #3CBE8B;
  overflow: hidden;
  position: relative;

}

.buttons {
  z-index: 2 !important;
  /*width: 50%;*/
  transition: all .3s ease-in-out;
  color: #3CBE8B;
  font-size: 15px;
  width: 100px;
  border: solid 1px #3CBE8B;
  padding: 5px;
  border-radius: 10px;
}

.switch {
  width: 50%;
  height: 100%;
  background-color: #3CBE8B;
  border-radius: 10px;
  position: absolute;
  transition: left .3s ease-in-out;
  top: 0;
  z-index: 1 !important;
}

.enter-icon {
  border: 2px solid white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 10px;
  height: 17px;
  border-right: none;

}
</style>