<template>
  <div>
    <v-window v-model="step" :reverse="this.$store.getters.isRTL" touchless>
      <v-window-item :value="1">
        <p class="text-center " :class="from != 'PSYTEST' && 'titleRegister'">
          {{ title }}
        </p>
        <v-card-text class="pb-1">
          <v-form ref="signupform" @keyup.native.enter="register" :class="classTagManager">
            <v-row dense>
              <v-col cols="12" dense>
                <v-text-field
                    :loader-height="0"
                    style="font-size: 15px;"
                    @keydown.enter.prevent='register'
                    v-model="mutableEmail"
                    type="email"
                    name="email"
                    color="#3CBE8B"
                    required
                    prepend-inner-icon="far fa-envelope"
                    :label="$t('emailLabel')"
                    :rules="emailRules"
                    class="pa-0 ma-0">
                </v-text-field>
                <div class="phoneDiv">
                  <v-text-field
                      :loader-height="0"
                      style="font-size: 1em"
                      dir="rtl"
                      type="tel"
                      name="tel"
                      placeholder="+1 555 123 4567"
                      v-model="phone"
                      :rules="phoneRules"
                      :label="$t('signupComponent.phone')"
                  >
                  </v-text-field>
                </div>
                <v-text-field
                    :loader-height="0"
                    style="font-size: 1em;"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :prepend-inner-icon="'mdi-lock-outline'"
                    @click:append="showPass = !showPass"
                    :type="showPass ? 'text' : 'password'"
                    v-model="password"
                    name="password"
                    :label="$t('passwordLabel')"
                    :rules="passwordRules"
                ></v-text-field>

                <v-text-field
                    :loader-height="0"
                    style="font-size: 1em;"
                    :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :prepend-inner-icon="'mdi-lock-outline'"
                    @click:append="showConfirmPass = !showConfirmPass"
                    :type="showConfirmPass ? 'text' : 'password'"
                    v-model="confirmPassword"
                    :label="$t('passwordConfirmationLabel')"
                    :rules="passwordConfirmRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <template v-if="inviter!=null">
              <div class="px-2">
                {{ $t('signupComponent.inviter') }}
                <v-chip small class="blue white--text">{{ inviter }}</v-chip>
              </div>
            </template>

          </v-form>
          <div class="mb-3" style="width: 100%; text-align: center;margin: 0 auto;">
            <v-btn
                :loading="btnLoading"
                :disabled="btnLoading"
                color="#3CBE8B"
                class="white--text mt-2"
                height="50"
                @click="register"
                block
                elevation="0"
                style="border-radius: 10px;!important;"
            >
              {{ $t('signupComponent.registerAction') }}
              <span style="font-size: 10px;margin: 0px 5px">
                   ({{ $t('signupComponent.acceptTerms') }})
              </span>

            </v-btn>
          </div>
        </v-card-text>
        <template v-if="! onlyRegister">
          <v-card-text style="font-size: .6em" class="justify-center mt-0 mb-0 pb-2 pt-0 text-center">
          <span style="color: #6d6d6d">
                          {{ $t('signupComponent.loginLabel') }}
          </span>
            <a @click="changeParentWindow(1)"
               style="color: #3CBE8B"
               id="gotoLogin"
            >
              {{ $t('signupComponent.loginActionOnBoarding') }}
            </a>
          </v-card-text>
        </template>

        <!--<v-card-text v-show="registerErr.show" class="red&#45;&#45;text text-center">{{ registerErr.msg }}</v-card-text>-->
      </v-window-item>
    </v-window>
  </div>
  <!--</v-card>-->

</template>
<script>
/*eslint-disable*/
import {parsePhoneNumberFromString, AsYouType} from 'libphonenumber-js'
// import CountryFlag from 'vue-country-flag';
import {EventBus} from "@/event-bus";
// import Tracker from '../tracker';
import {countriesCodes} from '../../../Lib/countriesCodes';
import {noRedirectPages} from "../../../Lib/utilities";
import InputWrapper from "@/components/Main/Wrapper/InputWrapper";
import AuthMixins from "@/mixins/AuthMixins";
import EventsMixins from "@/mixins/EventsMixins";

export default {
  mixins: [AuthMixins, EventsMixins],
  data() {
    return {
      validating: false,
      phoneError: false,
      btnLoading: false,
      registered_on: new Date(),
      selectedCountry: null,
      countries: countriesCodes,
      inviter: null,
      privacyPolicy: false,
      phone: '',
      password: "",
      confirmPassword: "",
      showPass: false,
      showConfirmPass: false,
      classTagManager: '',
      registerErr: {
        show: false,
        msg: '',
      },
      mutableEmail: '',
    }
  },

  mounted() {
    let tempPhone = this.$store.getters.getTempMobile
    if (tempPhone != null) {
      this.phone = tempPhone
    }
  },
  props: {
    from: {
      default: null,
    },
    wizard: {
      default: false
    },
    dense: {
      default: false
    },
    title: {
      default: 'ایجاد حساب کاربری'
    },
    GASignup: {
      default: 'ga_signup'
    },
    preorderLogin: {
      default: false
    },
    email: String,

    step: {
      type: Number,
      default: 1
    },
    onlyRegister: {
      type: Boolean,
      default: false,
      require: false,
    },
    campaignName: {
      type: String,
      require: false,
      default: null
    },
    userTestId: {
      type: [String, Boolean],
      default: false,
      require: false,
    },
    // inviter: {
    //     default: null
    // }
  },

  created() {

    // this.countries = countryCodes;
    this.mutableEmail = this.email;
    this.inviter = this.$store.getters.getInviter;
  },

  watch: {},
  components: {
    InputWrapper
    // CountryFlag
  },
  filters: {},
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return this.$t('signUpLabel')
        case 2:
          return this.$t('setAPassword')
        default:
          return 'Account created'
      }
    },
  },
  methods: {
    isCodeAutoComplete(event) {
      return event.target.id === 'ccode';
    },
    phoneFilter(item, queryText, itemText) {

      if (item == null) {
        return false;
      }
      if (item.header) {
        return false;
      }

      const hasValue = val => val != null ? val : '';

      const text = hasValue(itemText);
      const code = hasValue(item.code);
      const query = hasValue(queryText);

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1 || code.indexOf(query.toString().toLowerCase()) > -1;
    },
    register() {

      // if (this.isCodeAutoComplete(event)) {
      //   return;
      // }
      this.validating = true;
      if (this.$refs.signupform.validate()) {
        let phoneNumber;
        // let phone = this.selectedCountry.code + this.phone.replace(' ', '');
        let phone = this.phone.replaceAll(' ', '');
        phoneNumber = parsePhoneNumberFromString(phone);
        if (phoneNumber) {
          if (!phoneNumber.isValid()) {
            this.$emitEvent('notify', {color: 'red', title: this.$t('Notify.signupComponent.phoneNumberError')});
            return;
          }
        } else {
          this.$emitEvent('notify', {color: 'red', title: this.$t('Notify.signupComponent.phoneNumberError')});
          return;
        }

        // if (!this.privacyPolicy) {
        //   EventBus.$emit('notify', 'red', undefined, 'موافقت با شرایط و ضوابط الزامیست.');
        //   return;
        // }

        if (this.confirmPassword === this.password) {
          let newUser = {
            email: this.mutableEmail,
            password: this.password,
            password_confirmation: this.confirmPassword,
          };
          if (this.userTestId) {
            newUser.guestParticipantId = this.userTestId
          }
          Object.assign(newUser, {
            mobile: phoneNumber.number,
          })
          if (this.campaignName != null) {
            Object.assign(newUser, {
              state: this.campaignName
            })
          }
          if (this.from != null) {
            Object.assign(newUser, {state: this.from})
          }

          if (this.inviter != null) {
            newUser.inviter = this.inviter;
          }
          // if(this.$router.currentRoute.name == 'preorder') localStorage.setItem('preorder', this.$route.params.orderId);
          this.$emitEvent('setLoading', true);
          this.btnLoading = true;
          this.requesting('auth', 'register', '', newUser).then((data) => {
            this.$store.dispatch('removeInviter');
            this.$emitEvent('notify', {
              color: 'green',
              title: this.$t('Notify.signupComponent.success'),
              id: this.GASignup
            });
            if (this.campaignName == !null) {
              this.classTagManager = this.campaignName + "_campaignRegister"
            }
            this.requesting('auth', 'login', '', {
              email: this.mutableEmail,
              password: this.password
            }).then(() => {
              this.requesting('auth', 'getProfile').then(() => {
                this.$store.commit('setLoggedIn');
                this.$emit('userLoggedIn');
                this.$emit('successfullyRegistered');
                setTimeout(() => {
                  this.WebengageUserRegister(this.$store.getters.getProfile.id, this.registered_on, this.mutableEmail, newUser.mobile, this.from == 'PSYTEST')
                }, 100);
              });
            })
            // new Tracker("REGISTRATION", data.data.userId).dispatch()
          }).catch((err) => {
            this.btnLoading = false;
            // console.log('sign up err', err);
            if (err.response.status) {
              this.registerErr = {
                show: true,
                msg: this.$t('Notify.signupComponent.emailError')
              }
            }
            // else if (500 <= err.response.s < 600) {
            //     this.$store.commit('setSnackbar', {show: true, type: 'err500'});
            // }
          }).finally(() => {
            this.btnLoading = false;
            this.$emitEvent('setLoading', false);

          })
        } else if (this.confirmPassword !== this.password) {
          this.registerErr = {
            show: true,
            msg: this.$t('Notify.signupComponent.passwordError')
          }
        }
      } else {
        return;
      }

    },
    back() {
      this.step--;
      this.registerErr = {
        show: false,
        msg: ''
      }
    },
    changeParentWindow(value) {
      this.$emit('changeWindow', value);
    }
  },
}
</script>
<style>
/*#k-phone-input .text-field-outlined-dense-label-position-x {*/
/*  color: red !important;*/
/*}*/

/*.v-text-field.v-input--has-state > .v-input__control > .v-input__slot::before {*/
/*  border-color: #b9b9b9;*/
/*}*/
/*.theme--light.v-text-field > .v-input__control > .v-input__slot::before {*/
/*  border-color: #b9b9b9;*/
/*}*/


.v-label {
  font-size: 14px;
  line-height: 1;
  min-height: 8px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  color: aqua;
}

@media (min-width: 200px) and (max-width: 600px) {
  .titleRegister {
    margin-top: 100px !important;
  }
}

.phoneDiv .v-text-field__slot input {
  direction: ltr !important;
}
</style>
