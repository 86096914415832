<template>
  <v-fade-transition>

    <!--                <div v-if="currentStep > 1 && currentStep != 6">-->

    <div>
      <div class="stepper-nav-btn  text-center" @click="previous"
           :class="{'enabled':canPrev,'disabled':!canPrev}"
           :style="checkLang == 'fa' ? 'right: 40px;' : 'left: 40px; '">
        <div class="nav-text" :style="checkLang == 'fa' ? 'right: 40px;' : 'left: 40px;'">
          {{ $t('onBoarding.prev') }}
        </div>
        <v-icon class="stepper-nav-icon">
          {{ checkLang == 'fa' ? 'mdi-menu-right' : 'mdi-menu-left' }}
        </v-icon>
      </div>

      <div class="stepper-nav-btn text-center" @click="next"
           :class="{'enabled':canNext,'disabled':!canNext}"
           :style="checkLang == 'fa' ? ' left: 40px;  ' : 'right: 40px; '">
        <div class="nav-text" :style="checkLang == 'fa' ? ' left: 40px; ' : 'right: 40px;'">
          {{ $t('onBoarding.next') }}
        </div>
        <v-icon class="stepper-nav-icon">
          {{ checkLang == 'en' ? ' mdi-menu-right ' : 'mdi-menu-left' }}
        </v-icon>
      </div>


    </div>
  </v-fade-transition>
</template>

<script>
export default {
  computed: {
    previousHasValue() {
      if (this.currentStep == 2) {
        return true;
      } else if (this.currentStep > 2) {
        if (this.currentStep == 8) {
          if (this.info['whoSelectDoctor'] == 'me') {
            return this.info[this.validations[`step${this.currentStep - 1}`]] != null;
          } else {
            return this.info[this.validations[`step${this.currentStep - 2}`]] != null;
          }
        }
        return this.info[this.validations[`step${this.currentStep - 1}`]] != null;
      }
      return false;
    },
    checkLang() {
      return this.$route.params.lang == "fa" || this.$route.params.lang == undefined ? 'fa' : 'en';
    }
  },
  props: {
    currentStep: {
      default: 1
    },
    info: {},
    days: {},
    canNext: Boolean,
    canPrev: Boolean
  },
  // watch:{
  //   info: function(newVal, oldVal) { // watch it
  //     console.log('Prop changed: ', newVal, ' | was: ', oldVal)
  //   }
  // },
  updated() {
    // alert('vsvsdv')
  },
  data() {
    return {
      validations: {
        step2: 'category',
        step3: 'gender',
        step4: 'age',
        step5: 'relationship',
        step6: 'whoSelectDoctor',
        step7: 'doctor',
        step8: 'times'
      },
      test: true
    }
  },
  methods: {
    next() {
      // if (this.currentHasValue)
      if (this.canNext) {
        this.$emit('nextHandler');
      }
    },
    previous() {
      // if (this.previousHasValue)
      this.$emit('previousHandler');
    },
    checkNextBtnForTimes() {
      // console.log(this.info.times , 'me')
      // if (this.info.times != null){

      // }

    }
  }
}
</script>

<style scoped>

@media screen and (max-width: 800px) {
  .stepper-nav-btn {
    top: unset !important;
    bottom: 12% !important;
    width: 24px !important;
    height: 24px !important;
    border-radius: 7px !important;
  }


  .stepper-nav-icon {
    font-size: .7em !important;
    margin-top: -10px !important;
    /*margin-left: 3px !important;*/

  }

  .nav-text {
    transition: all .3s linear;
    position: absolute;
    font-size: 13px;
    top: 2px !important;
    /*background-color: red;*/
  }

}

.stepper-nav-icon {
  font-size: 1em;
  margin-bottom: 5px !important;
}

.stepper-next-btn {
  left: 40px;
}

.stepper-next-btn .stepper-next-btn {
  font-size: .6em;
  margin-bottom: 3px;
  margin-right: 3px;

}


.stepper-previous-btn .stepper-next-btn {
  font-size: .6em;
  margin-bottom: 3px;
  margin-left: 3px;

}


.stepper-next-btn .nav-text {
  right: -35px;

}

.stepper-nav-btn {

  border-radius: 10px;
  position: absolute;
  z-index: 20;
  top: calc(50% - 18px);

  width: 36px;
  height: 36px;
  transition: border .3s linear;
}

.stepper-nav-btn.disabled {
  border: solid 1px #dbdbdb;
}

.stepper-nav-btn.disabled .stepper-nav-icon {
  color: #dbdbdb;
}

.stepper-nav-btn.enabled {
  border: solid 1px #6d6d6d;
  cursor: pointer;
}

.stepper-nav-btn.enabled .stepper-nav-icon {
  color: #6d6d6d;
}

.stepper-nav-btn.enabled:hover {
  border: solid 1px #000;
}

.stepper-nav-btn.enabled:hover .stepper-nav-icon {
  color: #000;
}

.stepper-nav-btn.enabled .nav-text {
  color: #6d6d6d !important;
}

.stepper-nav-btn.disabled .nav-text {
  color: #dbdbdb !important;
}


.stepper-previous-btn {
  right: 40px;
}

.stepper-previous-btn .nav-text {
  left: -30px;
}

.nav-text {
  transition: all .3s linear;
  position: absolute;
  font-size: 13px;
  top: 7px;
  /*background-color: red;*/
}

</style>