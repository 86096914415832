<template>
  <div class="singleSelect" @click="select()">
    <v-fade-transition>
      <div class="select-card-check" v-if="who == value">
        <v-icon size="18" style="margin-right: 1px;margin-top: -2px;" color="white">mdi-check</v-icon>
      </div>
    </v-fade-transition>
    <span class="mr-7">
      {{title}}
    </span>
  </div>
</template>

<script>
export default {
  props:{
    title:String,
    value:String,
    who:String,
  },
  methods:{
    select(){
      this.$emit('whoSelectHandler',this.value)
    }
  }
}
</script>

<style scoped>
.singleSelect{
  background: #d1fdff;
  border: solid 2px #d1dcff;
  padding: 10px;
  border-radius: 10px 10px;
  margin-top: 10px;
  font-size: 14px;
  position: relative;
}
.select-card-check{
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #3CBE8B;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}
.singleSelect:hover{
  cursor: pointer;
}

</style>