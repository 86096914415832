<template>
  <div>
    <div class="indicatorInner">
      <div class="activeArea"></div>
    </div>
    <div class="indicatorLabels d-flex justify-space-between">
      <div :class=" step >= 0 && 'activeLabel'">{{ $t('new_onboarding.new_indicator.info') }}</div>
      <div :class=" step >= 6 && 'activeLabel'">  {{ $t('new_onboarding.new_indicator.title_session') }}</div>
      <div :class=" step >= 7 && 'activeLabel'">{{ $t('new_onboarding.new_indicator.register') }}</div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  props: {
    step: {
      default: 2
    }
  },
  computed: {
    widthIndicatorPointer() {
      let width = 0;
      if (this.step <= 4) {
        width += 33.3
      } else if (this.step <= 6) {
        width += 66.6
      } else {
        width = 100
      }
      return width + "%";
    }
  },
  mounted() {
    gsap.to('.activeArea', {width: this.widthIndicatorPointer, duration: 1.5, ease: 'Power1.easeOut'})
  },
  watch: {
    widthIndicatorPointer(val) {
      if (val) {
        gsap.to('.activeArea', {width: this.widthIndicatorPointer, duration: 1, ease: 'Power1.easeOut'})
      }
    }
  }
}
</script>

<style scoped>
.indicatorInner {
  background: #f1f5ff;
  width: 250px;
  height: 23px;
  margin: 20px auto;
  border-radius: 7px;
  position: relative;
  padding: 4px;
}

.indicatorLabels {
  width: 200px;
  margin: -10px auto;
  font-size: 10px;

}

.activeArea {
  background: #78a5ff;
  height: 15px;
  width: 0%;
  border-radius: 7px;
}

.activeLabel {
  color: #78a5ff;
}
</style>