<template>
  <div class="stepper-item-com">
    <template>
      <v-card color="transparent" elevation="0" style="">
        <div class="text-center mt-10" style="font-size: .8em;">
          {{ $t('onboarding.country.title') }}
          <div>
                <span style="" class="step-subtitle text-right">
          {{ $t('onboarding.country.subTitle') }}
                 </span>
            <div style="" class="mt-4 text-right px-5 ">
              <v-form ref="country" @submit.prevent="submitCountry">
                <v-autocomplete :label="$t('country')" v-model="country" outlined dense :rules="requiredRules"
                                :items="contires"
                                item-text="label"
                                item-value="id" @input="submitCountry"></v-autocomplete>
                <div class="text-center">
                  <v-btn @click="submitCountry" elevation="0" class="white--text" color="#3CBE8B">
                    {{ $t('next') }}
                  </v-btn>
                </div>
              </v-form>
            </div>
          </div>
          <!--          <v-btn @click="nextHandler">sdvsv</v-btn>-->
        </div>


      </v-card>
    </template>
  </div>
</template>

<script>

export default {
  props: {
    isMobile: Boolean,
    info: Object,
    checkLang: String,
    dataComponent: Array,
    // key:String,
    datakey: String,
  },
  data() {
    return {
      country: null,
    }
  },
  mounted() {
    this.country = this.info.country
  },
  methods: {
    nextHandler() {
      this.$emit('nextHandler')
    },
    selectItem(key, value) {
      this.$emit('selectItem', key, value, false, true)
    },
    submitCountry() {
      if (this.$refs.country.validate()) {
        this.selectItem('country', this.country)
      }
    }
  },
  watch: {}
}
</script>

<style scoped>
.stepper-item-com {
  /*width: 100%;*/
  /*min-height: 100%;*/
  /*margin: 0 auto;*/
  /*position: absolute;*/
  /*opacity: 0;*/
  /*display: none;*/
  display: flex !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  transition: background-color .3s linear;
}
</style>